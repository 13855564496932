import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc,deleteDoc } from "firebase/firestore";
import { useLocation } from 'react-router-dom';

import confirmIcon from '../../images/confirm.svg';
import declineIcon from '../../images/decline.svg';
import declineIconred from '../../images/closered.svg';
import { auth, db } from './../../firebaseConfig';
import edit from './../../images/edit.svg';
import editWhite from './../../images/edit-white.svg';
import Select, { components } from 'react-select';
import { customStyles } from './../../helpers/StyleUtils';

const ListViewAllBookings = ({ events, authToken }) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedTalent, setSelectedTalent] = useState(null);
    const [selectedSet, setSelectedSet] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    let userEmail=localStorage.getItem("userEmail");
    const [filterPreferences, setFilterPreferences] = useState([]);
    const [selectedPreferenceIndex, setSelectedPreferenceIndex] = useState(null); 
    const [selectedOrders, setSelectedOrders] = useState({});
    useEffect(() => {
        const fetchPreferences = async () => {
            if (!userEmail) return;

            const usersRef = collection(db, `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`);
            const q = query(usersRef, where("email", "==", userEmail));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userData = querySnapshot.docs[0].data();
                const userPrefs = userData.filterPreferences;
                if (userPrefs) {
                    setFilterPreferences(userPrefs);
                }
            } else {
                console.log("No user or preferences found.");
            }
        };

        fetchPreferences();
    }, [userEmail]);
  
    const applyFilterPreference = (index) => {
        if (index < 0 || index >= filterPreferences.length) {
            console.log("Invalid filter preference index.");
            return;
        }

        const preference = filterPreferences[index];
        setSearchTerm(preference.searchTerm);
      
    
        setSelectedClient(preference.selectedClient);
        setSelectedPosition(preference.selectedPosition);
        setSelectedSet(preference.selectedSet);
        setSelectedLocation(preference.selectedLocation);
        setSelectedStatus(preference.selectedStatus);
        // Apply other filters as needed
    };

    const preferenceOptions = filterPreferences.map((_, index) => ({ value: index, label: `Preference ${index + 1}` }));
    const handlePreferenceChange = selectedOption => {
        setSelectedPreferenceIndex(selectedOption ? selectedOption.value : null);
        if (selectedOption) {
            applyFilterPreference(selectedOption.value);
        } else {
            // Clear all filter preferences here
            setSearchTerm("");
  
        
            setSelectedClient(null);
            setSelectedPosition(null);
            setSelectedSet(null);
            setSelectedLocation(null);
            setSelectedStatus(null);
            // Reset any other filter states back to their initial values here
        }
    };
    

    const saveOrUpdateFilterPreference = async () => {
        const newPreference = {
            searchTerm,
    
            selectedClient,
            selectedPosition,
            selectedSet,
            selectedLocation,
            selectedStatus,
            // Add other filter settings as needed
        };
    
        if (selectedPreferenceIndex !== null) {
            // Update an existing preference
            filterPreferences[selectedPreferenceIndex] = newPreference;
        } else {
            // Save a new preference if there's room
            if (filterPreferences.length < 3) {
                filterPreferences.push(newPreference);
            } else {
                alert('You can only save up to 3 filter preferences.');
                return;
            }
        }
    
        // Fetch the user's document reference again to ensure we have the correct document to update
        const usersRef = collection(db, `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`);
        const q = query(usersRef, where("email", "==", userEmail));
        const querySnapshot = await getDocs(q);
    
        if (!querySnapshot.empty) {
            const userDocRef = querySnapshot.docs[0].ref; // Document reference
            // Update the document with the new or updated preferences
            try {
                await updateDoc(userDocRef, { filterPreferences: filterPreferences });
                setFilterPreferences([...filterPreferences]); // Update local state
                alert('Filter preferences updated successfully.');
            } catch (error) {
                console.error('Error updating preferences:', error);
                alert('Failed to update filter preferences.');
            }
        } else {
            console.log("User document not found.");
        }
    };
    
    
      const DropdownIndicator = props => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <div style={{ width: '16px', margin: '0' }}>
                <svg version="1.1" id="Isolationsmodus" x="0px" y="0px" viewBox="0 0 322.9 257.78" shapeRendering="geometricPrecision">
                  <path xmlns="http://www.w3.org/2000/svg" d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z" />
                </svg>
              </div>
            </components.DropdownIndicator>
          )
        );
      };

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const fetchBooking = async (bookingId) => {
      const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
      const bookingDoc = await getDoc(bookingRef);

      if (!bookingDoc.exists) {
          console.error("Booking not found");
          return null;
      }

      return bookingDoc.data();
  };

  // Fetch talent based on talentId
  const fetchTalent = async (talentId) => {
      const talentRef = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId);
      const talentDoc = await getDoc(talentRef);

      if (!talentDoc.exists) {
          console.error("Talent not found");
          return null;
      }

      return talentDoc.data();
  };
  const acceptporposedtalent = async (bookingId) => {
    const userConfirmed = window.confirm("Are you sure you want to comfirm?");
    if (userConfirmed) {
        const booking = await fetchBooking(bookingId);
        const talent = await fetchTalent(booking.talentId);
        // Check if it's a new booking or an update
        if (bookingId) {
            // Update existing booking
            try {
                const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
                await updateDoc(bookingRef, {
                    status: 'optioned',  // Please note: 'comfirmed' seems like a typo, it should be 'confirmed'
                });
                alert("Booking updated successfully.");
                createTask(booking, talent);
              //  createmailcomfirmed(booking, talent);
              //  createmailtalentcomfirmed(booking, talent);
            } catch (error) {
                console.error("Error updating booking: ", error);
            }
        }
    } else {
        console.log("You have cancelled the operation.");
    }
}


    const declineproposedtalent = async (bookingId)=>{
      const userConfirmed = window.confirm("Are you sure you want to reject this order?");
      if (userConfirmed) {
      const booking = await fetchBooking(bookingId);
      const talent = await fetchTalent(booking.talentId);

        // Check if it's a new booking or an update
        if (bookingId) {
          // Update existing booking
          try {
            const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
    
              await updateDoc(bookingRef, {
    
                status: 'declined request',
              });
    
              alert("Booking declined successfully.");
            //  createmaildeclined(booking, talent);
         
            
          } catch (error) {
            console.error("Error updating booking: ", error);
          }
        }
      } else {
        console.log("You have cancelled the operation.");
    }

}

const createmailcomfirmed = async (filteredBooking, talent, start) => {

  const msg = {
    to: 'booking@set-cast.com, talent@mondula.com,' +localStorage.getItem("userEmail"),
    message: {
      subject: 'Planning Orendt has accepted the booking ',
      text: ` ${JSON.stringify(filteredBooking)}`,
      html: `
      Dear  Team,
      
      
      <p>Planning Orendt has accepted the booking and the Order has been confirmed</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://dev.app.set-cast.com/">https://dev.app.set-cast.com/ </a>
     
      
      
      </p>`,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};


const createmailtalentcomfirmed = async (filteredBooking, talent, start) => {

  const msg = {
    to: localStorage.getItem("userEmail")+', booking@set-cast.com, talent@mondula.com,'+  talent.E_Mail,
    message: {
      subject: 'Planning Orendt has accepted the booking ',
      text: `${JSON.stringify(filteredBooking)}`,
      html: `
      Dear  ${talent.Name} ,
      
      
      <p>Planning Orendt has accepted the booking and you are booked for:</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.Name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://dev.app.set-cast.com/">https://dev.app.set-cast.com/ </a>
     
      
      
      </p>`,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};

const createmaildeclined = async (filteredBooking, talent, start) => {

  const msg = {
    to:  localStorage.getItem("userEmail")+ ', booking@set-cast.com, talent@mondula.com' +  talent.E_Mail,
    message: {
      subject: 'Planning Orendt has declined the booking ',
      text: `${JSON.stringify(filteredBooking)}`,
      html: `
      Dear  Team,
      
      
      <p>Planning Orendt has declined the booking!</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://dev.app.set-cast.com/">https://dev.app.set-cast.com/ </a>
     
      
      
      </p>`,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};


function getWeekNumber(d) {
  // Copy date so don't modify the original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  
  // Get first day of the year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  
  return weekNo;
}

const createTask = async (booking, talent) => {
  console.log('create called' +authToken)
  // add entity - POST
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + authToken);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Access-Control-Allow-Origin', 'http://dev-setcast.mondula.com/');
  myHeaders.append('Access-Control-Allow-Credentials', 'true');
  const kw ='KW'+ getWeekNumber(new Date(booking.start_date.seconds * 1000)) + " " ;
 var titile= kw+'_' +booking.client.label+'_'+booking.position.label+'_'+new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0]+'-'+new Date(booking.end_date.seconds * 1000).toLocaleDateString().split('T')[0];

  // Replace hardcoded data with the booking details
  var raw = JSON.stringify({
    "task": {
      "title": titile,
      "description": booking.description || ' ',
      "details": booking.description || ' ',
      "starts_on": new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0],
      "ends_on": new Date(booking.end_date.seconds * 1000).toLocaleDateString().split('T')[0],
      "rate": talent.Tagessatz,
      "duration_in_time_units": booking.range,
      "currency": "EUR",
      
      "budget_setting": "set_budget",
      "assignee_id": talent.Lano_ID,
      "rate_type_id": 1,
      "creator_id": 216149,
      "state": "freelancer_assigned",
      "task_cost_centers_attributes": [
        {
            "cost_center_1_id": booking.costcenter1.id,
            "cost_center_2_id": booking.costcenter2.id
        }
    ]
    }
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'manual'
  };

  fetch("https://api.lano.io/api/v1/tasks", requestOptions)
    .then(response => response.text())
    .then(result => { console.log(result); /* setMeddage(result); */ }) // Typo fix: it should probably be setMessage
    .catch(error => console.log('error', error));
}
const deleteorder =async (bookingId)=>{

  const userConfirmed = window.confirm("Are you sure you want to delete this order?");
  if (userConfirmed) {
    try {
      const bookingRef = doc(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
      await deleteDoc(bookingRef);
      console.log("Order deleted successfully.");
      // Optionally, update UI or state here to reflect the deletion
      alert("Order deleted successfully.");
      //  createmaildeclined(booking, talent);
   
      window.location.reload();
    } catch (error) {
      console.error("Error deleting order: ", error);
      alert("There has been an error.");
      //  createmaildeclined(booking, talent);
   
      window.location.reload();
      // Handle any errors, e.g., show an error message to the user
    }
  
  }
}
const clientOptions = [...new Set(events.map(event => event.client))].map(client => ({ value: client, label: client }));
const positionOptions = [...new Set(events.map(event => event.position))].map(position => ({ value: position, label: position }));
const talentOption = [...new Set(events.map(event => event.talent))].map(talent => ({ value: talent, label: talent }));
const statusOption = [...new Set(events.map(event => event.status))].map(status => ({ value: status, label: status }));
const setOption = [...new Set(events.map(event => event.set))].map(set => ({ value: set, label: set }));
const locationOptions = [...new Set(events.map(event => event.location))].map(set => ({ value: set, label: set }));

    const filteredEvents = events.filter(event => {
      const matchesSearchTerm =
     ( event.boktitle&&event.boktitle.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (event.set &&  event.set.toLowerCase().includes(searchTerm.toLowerCase())) ||
       ( event.talent&& event.talent.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (    event.status&&   event.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (  event.company&&  event.company.toLowerCase().includes(searchTerm.toLowerCase()));

      const matchesClient = !selectedClient || event.client === selectedClient.value;
      const matchesPosition = !selectedPosition || event.position === selectedPosition.value;
      const matchesTalent = !selectedTalent || event.talent === selectedTalent.value;
      const matchesSet = !selectedSet || event.set === selectedSet.value;
      const matchesStatus = !selectedStatus || event.status === selectedStatus.value;
      const matchesLocation = !selectedLocation || event.location === selectedLocation.value;

      return matchesSearchTerm  && matchesClient && matchesPosition && matchesTalent &&matchesSet &&matchesStatus && matchesLocation;
  });

    const handleCheckboxChange = (index) => {
        setSelectedOrders((prevSelected) => ({
            ...prevSelected,
            [index]: !prevSelected[index],
        }));
    };

    const deleteSelectedOrders = async () => {
        const selectedOrderIds = Object.keys(selectedOrders).filter(
            (id) => selectedOrders[id]
        );

        if (selectedOrderIds.length === 0) {
            console.log("No orders selected.");
            return;
        }

        const userConfirmed = window.confirm("Are you sure you want to delete the selected orders?");
        if (!userConfirmed) return;

        try {
            const deletePromises = selectedOrderIds.map(async (orderId) => {
                const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", orderId);
                await deleteDoc(bookingRef);
            });

            await Promise.all(deletePromises);
            alert("Selected orders deleted successfully.");
            window.location.reload();
        } catch (error) {
            console.error("Error deleting selected orders: ", error);
            alert("There has been an error.");
        }
    };

    return (
        <div>
          <div></div>
            <div style={{ marginTop: '40px', marginBottom: '60px' }}>
              <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleChange}
                  className='inputtext search'
              />
           
            <div>
              
              </div>

              <div style={{ marginTop: '40px', marginBottom: '10px', display: 'flex', }}>
    
          
    <Select
            value={preferenceOptions.find(option => option.value === selectedPreferenceIndex)}
            onChange={handlePreferenceChange}
            options={preferenceOptions}
            isClearable
            placeholder="Select Preference"
            styles={customStyles}
        />
             <button className='button'  onClick={() => saveOrUpdateFilterPreference()}>Save/Update </button>
    </div> 
  
              <div style={{ marginTop: '0px', marginBottom: '60px', display: 'flex', }}>
            <div style={{ marginRight: '10px' }}>
                    <Select
                        value={selectedClient}
                        onChange={setSelectedClient}
                        options={clientOptions}
                        isClearable
                        placeholder="Filter by Client"
                        components={{ DropdownIndicator }}
                        styles={customStyles}
                    />
                </div>

                <Select
                    value={selectedPosition}
                    onChange={setSelectedPosition}
                    options={positionOptions}
                    isClearable
                    placeholder="Filter by Function"
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                />
                     <Select
                    value={selectedTalent}
                    onChange={setSelectedTalent}
                    options={talentOption}
                    isClearable
                    placeholder="Filter by Talent"
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                />
                            <Select
                    value={selectedSet}
                    onChange={setSelectedSet}
                    options={setOption}
                    isClearable
                    placeholder="Filter by Set"
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                />
                            <Select
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    options={statusOption}
                    isClearable
                    placeholder="Filter by Status"
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                />
                
                            <Select
                    value={selectedLocation}
                    onChange={setSelectedLocation}
                    options={locationOptions}
                    isClearable
                    placeholder="Filter by Location"
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                />
            </div>
            <button className="button-red" onClick={deleteSelectedOrders}>
    Delete Selected Orders
</button>
            </div>

            <div className={`content-wrapper content-wrapper-talent-list header-list-wrapper`}>
    <div className='list-tasks-header' style={{ display: 'flex' }}>
        <div style={{ flexBasis: '40px' }}></div>
        <div style={{ flexBasis: '150px' }}>start/end</div>
        <div style={{ flexBasis: '100px' }}>set</div>
        <div style={{ flexBasis: '80px' }}>kpi</div>
        <div style={{ flexBasis: '100px' }}>fun.</div>
        <div style={{ flexBasis: '100px' }}>option</div>
        <div style={{ flexBasis: '100px' }}>talent</div>
        <div style={{ flexBasis: '100px' }}>status</div>
        <div style={{ flexBasis: '100px' }}>client</div>
        <div style={{ flexBasis: '100px' }}>S.info</div>
        <div style={{ flexBasis: '80px' }}></div>
    </div>
    <div style={{ width: '100%', height: '40px' }}></div>

    {filteredEvents.slice().reverse().map(event => (
        <div className='list-tasks-item' style={{ display: 'flex' }} key={event.id}>
            <div style={{ flexBasis: '40px' }} className="custom-checkbox">
                <input
                    type="checkbox"
                    id={`checkbox-${event.id}`}
                    checked={!!selectedOrders[event.id]}
                    onChange={() => handleCheckboxChange(event.id)}
                />
                <label htmlFor={`checkbox-${event.id}`}></label>
            </div>
            <div style={{ flexBasis: '150px', whiteSpace: 'pre-wrap' }}>
                {new Date(event.start).toLocaleDateString()} - 
                {new Date(event.end).toLocaleDateString()}
            </div>
            <div style={{ flexBasis: '100px' }}>{event.set}</div>
            <div style={{ flexBasis: '80px' }}>{event.kpi}</div>
            <div style={{ flexBasis: '100px' }}>{event.position}</div>
            <div style={{ flexBasis: '100px' }}>{event.option}</div>
            <div style={{ flexBasis: '100px' }}>{event.talent}</div>
            <div style={{ flexBasis: '100px' }}>{event.status}</div>
            <div style={{ flexBasis: '100px' }}>{event.client}</div>
            <div style={{ flexBasis: '100px' }}>{event.SonstigeInfo}</div>
            <div style={{ flexBasis: '60px' }}></div>
            {localStorage.getItem("userRole") !== 'planning' && (
                <div id="hideclient" style={{ flexBasis: '80px' }}>
                    <a href={`detail?id=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline', paddingRight: 5 }}>
                        <img src={edit} alt="Edit" className="edit-container" />
                    </a>
                </div>
            )}
        </div>
    ))}
</div>



        </div>
    );
};

export default ListViewAllBookings;
