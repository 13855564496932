import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import '../../../App.css';
import { collection, getDocs, doc, query, where, getDoc, Timestamp, updateDoc, addDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { NonceProvider } from 'react-select';
import CalendarToolbar from './../../CalendarToolbar';
import CalendarEventStatusColor from './../../CalendarEventStatusColor';
import edit from '../../../images/edit.svg'
import 'moment/locale/de'  // or any locale that uses day-month-year format
import NewOrdersList from '../../SetCastUser/NewOrdersList';
import ListViewProposed from './ListViewProposed';
import confirmIcon from '../../../images/confirm.svg';
import declineIcon from '../../../images/decline.svg';
import pauseIcon from '../../../images/onhold.svg';
import swapIcon from '../../../images/swap.svg';

const localizer = momentLocalizer(moment);

class ProposedBookingsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            view: 'list',
            holdevents:[],
            allevevents: [],
        };
    }

    componentDidMount() {
        this.fetchBookings();
    }
    fetchTalent = async (talentId) => {
        const talentDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId); // replace 'talents' with your actual talents collection name
        const talent = await getDoc(talentDoc);

        if (talent.exists) {
            return talent.data().Name; // assuming the talent document has a 'name' field
        } else {
            return 'New request';
        }
    }
    fetchCompany = async (companyID) => {
        const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1', companyID); // replace 'talents' with your actual talents collection name
        const company = await getDoc(companyDoc);

        if (company.exists) {
            return company.data().name; // assuming the talent document has a 'name' field
        } else {
            return 'deleted company';
        }
    }
    fetchBookings = async () => {
        const bookingCollection = collection(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings');
        const q = query(bookingCollection, where("status", "==", "optioned"));
        const hold = query(bookingCollection, where("status", "==", "hold"));
    
        const bookingSnapshot = await getDocs(q);
        const bookings = bookingSnapshot.docs.map(bookingDoc => {
            const bookingData = bookingDoc.data();
            return {
                id: bookingDoc.id,
                ...bookingData,
            };
        });
    
        const holdSnapshot = await getDocs(hold);
        const holdbookings = holdSnapshot.docs.map(bookingDoc => {
            const bookingHoldData = bookingDoc.data();
            return {
                id: bookingDoc.id,
                ...bookingHoldData,
            };
        });
    
        const holdevents = await Promise.all(holdbookings.map(async (booking) => {
            const results = await Promise.allSettled([
                this.fetchTalent(booking.talentId),
                this.fetchCompany(booking.companyID)
            ]);
            const talentName = results[0].status === 'fulfilled' ? results[0].value : 'New request';
            const companyName = results[1].status === 'fulfilled' ? results[1].value : 'Deleted company';
            const clientName = booking.client ? booking.client.label : 'NULL';
    
            return {
                bookingId: booking.id,
                id: booking.id,
                boktitle: booking.title,
                set: booking.set,
                kpi: booking.kpi,
                talent: talentName,
                title: `Booking ${talentName} for ${companyName} (${booking.status})`,
                start: new Date(booking.start_date.seconds * 1000),
                end: new Date(booking.end_date.seconds * 1000),
                allDay: true,
                status: booking.status,
                group: booking.group,
                groupColor: booking.groupColor,
                company: companyName,
                showDetail: false,
                client: clientName,
                position: booking.position.label,
                range: booking.range? booking.range : '', 
                segment: booking.segment,
                option: booking.option,
                startTime:booking.startTime,
                endTime:booking.endTime,
                talentId: booking.talentId,
                start_date: booking.start_date,
                createdBy:booking.createdBy,
                proposedBy:booking.proposedBy,
                studio:booking.studio,
                location:booking.location,
             
            };
        }));
    
        const events = await Promise.all(bookings.map(async (booking) => {
            const results = await Promise.allSettled([
                this.fetchTalent(booking.talentId),
                this.fetchCompany(booking.companyID)
            ]);
            const talentName = results[0].status === 'fulfilled' ? results[0].value : 'New request';
            const companyName = results[1].status === 'fulfilled' ? results[1].value : 'Deleted company';
            const clientName = booking.client ? booking.client.label : 'NULL';
    
            return {
                bookingId: booking.id,
                id: booking.id,
                boktitle: booking.title,
                set: booking.set,
                kpi: booking.kpi,
                talent: talentName,
                title: `Booking ${talentName} for ${companyName} (${booking.status})`,
                start: new Date(booking.start_date.seconds * 1000),
                end: new Date(booking.end_date.seconds * 1000),
                allDay: true,
                status: booking.status,
                group: booking.group,
                groupColor: booking.groupColor,
                company: companyName,
                showDetail: false,
                client: clientName,
                position: booking.position.label,
                startTime:booking.startTime,
                endTime:booking.endTime,
                studio:booking.studio,
       
                segment: booking.segment,
                option: booking.option,
                talentId: booking.talentId,
                start_date: booking.start_date,
                range: booking.range? booking.range : '', 
                createdBy:booking.createdBy,
                proposedBy:booking.proposedBy,
                location:booking.location,
                SonstigeInfo:booking.SonstigeInfo,
            };
        }));
    
        // Combine both arrays
        const allevents = [...events, ...holdevents];
        this.setState({ events, holdevents, allevents }); // Update the state with all events combined
    };
    


    handleSelectEvent = (event) => {
        const bookingId = event.bookingId;
        // Do something with the bookingId...
    };

    handleEventMouseEnter = (eventId) => {
        const updatedEvents = this.state.events.map((event) => {
            if (event.id === eventId) {
                return { ...event, showDetail: true }; // Set showDetail to true for the hovered event
            } else {
                return { ...event, showDetail: false }; // Set showDetail to false for all other events
            }
        });

        this.setState({ events: updatedEvents });
    };

    handleEventMouseLeave = (eventId) => {
        const updatedEvents = this.state.events.map((event) => {
            if (event.id === eventId) {
                return { ...event, showDetail: false }; // Set showDetail back to false for the event when mouse leaves
            }
            return event;
        });

        this.setState({ events: updatedEvents });
    };




    Event = ({ event }) => {
        let borderRadius;
        switch (event.set) {
            case 'On Model':
                borderRadius = '10px';
                break;
            case 'on Produkt':
                borderRadius = '0px';
                break;
        }

        const fetchBooking = async (bookingId) => {
            const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
            const bookingDoc = await getDoc(bookingRef);
      
            if (!bookingDoc.exists) {
                console.error("Booking not found");
                return null;
            }
      
            return bookingDoc.data();
        };
      
        // Fetch talent based on talentId
        const fetchTalent = async (talentId) => {
            const talentRef = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId);
            const talentDoc = await getDoc(talentRef);
      
            if (!talentDoc.exists) {
                console.error("Talent not found");
                return null;
            }
      
            return talentDoc.data();
        };
        const acceptporposedtalent = async (bookingId,groupId) => {
          const userConfirmed = window.confirm("Are you sure you want to confirm?");
      
          const booking = await fetchBooking(bookingId);
          const talent = await fetchTalent(booking.talentId);
      
          if (userConfirmed) {
         
                  try {
                      const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
                      await updateDoc(bookingRef, {
                          status: 'confirmed',  // Please note: 'comfirmed' seems like a typo, it should be 'confirmed'
                      });
                      await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
                      {
                        userId: booking.proposedBy,
                        message: `Planing has confirmed talent  ${talent.Name} for  ${booking.client.label}`,
                        read: false,
                        bookingId: bookingId,
                        url:'admin/detail?id='+bookingId,
                        createdAt: Timestamp.now()
                      }
                  
                    )
                     
                     // createTask(booking, talent);
                    //  createmailcomfirmed(booking, talent);
                      //createmailtalentcomfirmed(booking, talent);
                  } catch (error) {
                      console.error("Error updating booking: ", error);
                  }
              
              alert("Booking updated successfully.");
              window.location.reload();
          } else {
              console.log("You have cancelled the operation.");
          }
      }
      
      
      
      const hold = async (bookingId, groupId) => {
        const userConfirmed = window.confirm("Are you sure you want to Hold?");
      
        const booking = await fetchBooking(bookingId);
        const talent = await fetchTalent(booking.talentId);
      
        if (userConfirmed) {
    
                try {
                    const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
                    await updateDoc(bookingRef, {
                        status: 'hold',  // Please note: 'comfirmed' seems like a typo, it should be 'confirmed'
                    });
                    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
                    {
                      userId: booking.proposedBy,
                      message: `Planing has decited to hold this order for`,
                      read: false,
                      bookingId: bookingId,
                      url:'admin/detail&id='+bookingId,
                      createdAt: Timestamp.now()
                    }
                
                  )         // createTask(booking, talent);
                //  createmailcomfirmed(booking, talent);
                  //createmailtalentcomfirmed(booking, talent);
               
          
                } catch (error) {
                    console.error("Error updating booking: ", error);
                }
            
            alert("Booking updated successfully.");
            window.location.reload();
        } else {
            console.log("You have cancelled the operation.");
        }
      }
      
      
      const askAnotherTalent = async (bookingId, groupId) => {
        const userConfirmed = window.confirm("Are you sure you want to ask for another talent?");
        
      
        const booking = await fetchBooking(bookingId);
        const talent = await fetchTalent(booking.talentId);
      
        if (userConfirmed) {
        
                try {
                    const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
                    await updateDoc(bookingRef, {
                        status: 'new order',  // Please note: 'comfirmed' seems like a typo, it should be 'confirmed'
                    });
                    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"),
                    {
                    
                      status: 'talentOnHold', 
                      talentId:booking.talentId,
                      start_date: booking.start_date,
                      end_date: booking.end_date,
                      title:booking.title,
                      kpi:booking.kpi,
                      set:booking.set,
                      position:booking.position,
                      studio:booking.studio,
                      client: booking.client,
               
                      range: booking.range? booking.range : '', 
                      costcenter1: ' ',
                      costcenter2: ' ',
                      option: booking.option,
                      createdBy: booking.createdBy,
                      proposedBy:booking.proposedBy,
                      createdAt: Timestamp.now(),
                      startTime: booking.startTime,
                      endTime:booking.endTime,
               
                    }
                
                  )
                    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
                    {
                      userId: booking.proposedBy,
                      message: `Planing has asked for another talent for ${booking.client.label}`,
                      read: false,
                      bookingId: bookingId,
                      url:'admin/detail?id='+bookingId,
                      createdAt: Timestamp.now()
                    }
                
                  )
                
                   // createTask(booking, talent);
                //    createmailcomfirmed(booking, talent);
                //    createmailtalentcomfirmed(booking, talent);
                } catch (error) {
                    console.error("Error updating booking: ", error);
                }
            
            alert("Booking updated successfully.");
            window.location.reload();
        } else {
            console.log("You have cancelled the operation.");
        }
      }
      
      
      
      
      
          const declineproposedtalent = async (bookingId, groupId)=>{
            const userConfirmed = window.confirm("Are you sure you want to reject this order?");
            const booking = await fetchBooking(bookingId);
            const talent = await fetchTalent(booking.talentId);
          
            if (userConfirmed) {

                // Update existing booking
                try {
                  const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
          
                    await updateDoc(bookingRef, {
          
                      status: 'optionSolved',
                    });
                    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
                    {
                      userId: booking.proposedBy,
                      message: `Planing has canceled the talent ${talent.Name} for  ${booking.client.label}`,
                      read: false,
                      bookingId: bookingId,
                      url:'admin/detail?id='+bookingId,
                      createdAt: Timestamp.now()
                    }
                
                  )
          
              //      createmaildeclined(booking, talent);
               
                  
                } catch (error) {
                  console.error("Error updating booking: ", error);
                }
              
      
              alert("Booking declined successfully.");
              window.location.reload();
            } else {
              console.log("You have cancelled the operation.");
          }
      
      }
        return (
            <div className="my-div" style={{ cursor: 'auto', height: '100px', borderRadius: borderRadius }}

                onClick={() => this.handleEventMouseEnter(event.id)}
                onMouseLeave={() => this.handleEventMouseLeave(event.id)}
            >
                {event.showDetail && (
                    <div className='toltip'
                        style={{
                            position: 'absolute',
                            width: "600px",
                            height: "auto",
                            top: "10px",
                            left: "10px",
                            background: '#000000',
                            color: '#ffffff',
                            padding: '10px',
                            zIndex: 9999,
                            cursor: 'auto'
                        }}
                    >

                        <span style={{ position: 'relative', fontWeight: '600', color: '#fff', whiteSpace: 'pre-line' }}>
                            <div class="calendar-inner-cell-container">
                                <div>
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                                        {` Client `}
                                    </div>
                                    {` ${event.client} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Talent `}
                                    </div>
                                    {` ${event.talent} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Status `}
                                    </div>
                                    {` ${event.status} `}
                                </div>
                                <div>
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                                        {` Title `}
                                    </div>
                                    {` ${event.boktitle} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Set `}
                                    </div>
                                    {` ${event.set} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Kpi `}
                                    </div>
                                    {` ${event.kpi} `}
                                </div>
                                <div style={{ flexGrow: '1' }}>
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                                        {` Option `}
                                    </div>
                                    {` ${event.option} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Start time `}
                                    </div>
                                    {` ${moment(event.start).format('DD-MM-YYYY')} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` End time `}
                                    </div>
                                    {` ${moment(event.end).format('DD-MM-YYYY')} `}
                                </div>
                    
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '10px', width: '100%' }}>

                            <a className='action-button-white' onClick={() => acceptporposedtalent(event.bookingId, event.group)} style={{ marginRight: '10px' }}>
                                    <img src={confirmIcon} alt="Accept" title="Accept order" className="action-container" />
                                </a>
                                <a className='action-button-white' title="Cancel this order" onClick={() => declineproposedtalent(event.bookingId, event.group)} style={{ marginRight: '10px' }}>
                                    <img src={declineIcon} alt="CancelOrder" title="Decline order" className="action-container" />
                                </a>
                                <a className='action-button-white' onClick={() => hold(event.bookingId, event.group)} style={{ marginRight: '10px' }}>
                                    <img src={pauseIcon} alt="hold" title="Put on hold" className="action-container" />
                                </a>
                                <a className='action-button-white' onClick={() => askAnotherTalent(event.bookingId, event.group)}>
                                    <img style={{ width: '26px' }} src={swapIcon} alt="requestAnotherTalent" title="Swap talent" className="action-container" />
                                </a>
                                </div>
                        </span>

                    </div>
                )}

                <div>
                    <CalendarEventStatusColor event={event} status={event.status} />
                </div>
                <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.startTime}-  ${event.endTime}`}</span>

                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.client} `}</span>

                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.talent} `}</span>

                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.status} `}</span>
                </span>
            </div>
        );
    }

    switchView = (view) => {
        this.setState({ view });
    }

    
    render() {
        return (
            <section>
                <div className='content-wrapper'>

                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
                            <h1>proposed orders</h1>
                            <button className={this.state.view === 'calendar' ? 'big-button' : 'big-button-active'} onClick={() => this.switchView('list')} style={{ marginLeft: 'auto' }}>List View</button>
                            <button className={this.state.view === 'calendar' ? 'big-button-active' : 'big-button'} onClick={() => this.switchView('calendar')}>Calendar View</button>
                        </div>
                        {this.state.view === 'calendar' ? (
                            <Calendar
                                localizer={localizer}
                                events={this.state.allevents}
                                startAccessor="start"
                                style={{   flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'visible',}} 
                                endAccessor="end"
                                defaultView='week'
                                onSelectEvent={event => this.handleSelectEvent(event)}
                                components={{
                                    event: this.Event,
                                    toolbar: CalendarToolbar,  // use the custom toolbar
                                }}
                                className="my-calendar"
                            />

                        ) : (
                            <ListViewProposed events={this.state.events} holdevents={this.state.holdevents} authToken={this.props.authToken} />
                        )}
                    </div>
                </div>
            </section>
        );
    }
}
export default ProposedBookingsPage;
