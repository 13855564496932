import React from 'react';
import setcastLogo from './../images/Setcast_Logo_Icon_White_RGB.svg';
import './../App.css';  // Ensure you create a CSS file for footer-specific styles

function Footer() {
    return (
        <div className="footer black-bg">
            <div className="footer-logo-container">
                <img style={{ height: 60 }} src={setcastLogo} alt="Setcast Logo" />
            </div>
        </div>
    );
}

export default Footer;
