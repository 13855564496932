import React from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import '../../../App.css';
import {
    collection,
    getDocs,
    query,
    where,
    doc,
    getDoc,
    onSnapshot,
    orderBy,
    startAt,
    endAt,
  } from "firebase/firestore";
import { db } from '../../../firebaseConfig';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarEventStatusColor from '../../CalendarEventStatusColor';
import { getWeek, startOfWeek, endOfWeek } from "date-fns";

import edit from '../../../images/edit.svg';
import MyCalendar from './MyCalendar';
import 'moment/locale/de'; // Import the German locale
const localizer = momentLocalizer(moment);
import CalendarToolbar from '../../CalendarToolbar';
const MyBookingsPageContext = React.createContext();
// Month names map
const monthNames = {
    JANUAR: 0,
    FEBRUAR: 1,
    MÄRZ: 2,
    APRIL: 3,
    MAI: 4,
    JUNI: 5,
    JULI: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OKTOBER: 9,
    NOVEMBER: 10,
    DEZEMBER: 11,
  };
  moment.locale("de");
class CustomToolbar extends React.Component {
    render() {
      let { label } = this.props;
      // Extract the month and start day from the label.
      const month = label.split(" ")[0].toUpperCase();
      const day = label.split(" ")[1].split("–")[0];
      // Construct the date using the extracted day and month.
      let date = new Date();
      date.setMonth(monthNames[month]);
      date.setDate(day);
      date = startOfWeek(date);
      // Get the week number using date-fns
      let weekNumber = getWeek(date);
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <div>
            <button className="button" onClick={() => this.navigate("TODAY")}>
              today
            </button>
          </div>
          <div className="weeknumber-wrapper">
            <label>
              Week {weekNumber}: <strong>{label}</strong>
            </label>
          </div>
          <div>
            <button className="button" onClick={() => this.navigate("PREV")}>
              Prev
            </button>
            <button className="button" onClick={() => this.navigate("NEXT")}>
              Next
            </button>
          </div>
        </div>
      );
    }
    navigate = (action) => {
      const { date } = this.props; // current date from the Calendar's props
      let newDate;
      switch (action) {
        case "PREV":
          newDate = moment(date).subtract(1, "week");
          break;
        case "NEXT":
          newDate = moment(date).add(1, "week");
          break;
        case "TODAY":
          newDate = moment();
          break;
        default:
          newDate = date;
      }
      // Notify the parent component
      this.props.onNavigate(action, newDate.toDate());
      // Notify about the week change
    };
    view = (view) => {
      this.props.onView(view);
    };
  }
class CustomDayHeader extends React.Component {
    render() {
        const { date, label } = this.props;
        const { availabilities } = this.context;
  
        const isAvailable = availabilities && availabilities.some(availability => 
          moment(date).isSameOrAfter(moment(availability.startDate)) && 
          moment(date).isSameOrBefore(moment(availability.endDate))
      );
      
  console.log(availabilities);
        const bgColor = isAvailable ? '#00d2ff' : 'white';
        const bgColorClass = isAvailable ? 'available-day' : '';
  
        return (
          <div className={`rbc-header ${bgColorClass}`}>
  
                {label}
            </div>
        );
    }
  }
  
  CustomDayHeader.contextType = MyBookingsPageContext;
class MyBookingsPage extends React.Component {
    constructor(props) {
        super(props);
        // Determine the initial view based on window width
        const mobileView = window.innerWidth <= 768;
        this.state = {
            events: [],
            availabilities: [],
            currentView: mobileView ? 'agenda' : 'week', // Set initial view here
            talentid:null,
            related: null, // Initialize talentid with null or empty string

        };
    }
    setCalendarView = () => {
        const mobileView = window.innerWidth <= 768; // You can adjust this value for your specific mobile breakpoint
        console.log(mobileView);
        const currentView = mobileView ? 'agenda' : 'week';
        console.log(currentView+"is the view ");

        this.setState({ currentView }, () => {
            this.forceUpdate();
        });
      };

      componentDidMount() {
        // Set the calendar view immediately
        this.setCalendarView();
        this.setState({talentid:localStorage.getItem("userId")});
    
        // Add the resize event listener
        window.addEventListener('resize', this.setCalendarView);
    
        // Fetch bookings and availabilities after setting the calendar view
        this.fetchBookingsForUserTalents()
            .then(this.fetchUserTalents)
            .then(talentIds => this.fetchAvailabilitiesForTalents(talentIds))
            .then(availabilities => this.setState({ availabilities }));
    }
    
    componentWillUnmount() {
       window.removeEventListener('resize', this.setCalendarView);
      }
    fetchTalent = async (talentId) => {
        const talentDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId);
        const talent = await getDoc(talentDoc);
        return talent.exists ? talent.data().Name : 'no name';
    }


    fetchCompany = async (companyID) => {
        const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1', companyID);
        const company = await getDoc(companyDoc);
        return company.exists ? company.data().name : 'deleted company';
    }

    fetchUserTalents = async () => {
        const userDoc = await getDoc(doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData", localStorage.getItem("userId")));
        console.log("Talent IDs for user:", userDoc.data().talentIds);
        this.setState({ related: userDoc.data().talentIds }); // Update state with fetched talentId
        
        return userDoc.data().talentIds || [];
    }
    fetchVacationsForTalent = async (talentId) => {
        const vacationsCollection = collection(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentVacations');
        const q = query(vacationsCollection, where("talentId", "==", talentId));
        
        const vacationSnapshot = await getDocs(q);
        
        return vacationSnapshot.docs.map(doc => doc.data());
    }
    
    fetchAvailabilitiesForTalents = async (talentIds) => {
        const allAvailabilitiesPromises = talentIds.map(async talentId => {
            const availabilityCollection = collection(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentAvailability');
            const q = query(availabilityCollection, where("talentId", "==", talentId));
            const availabilitySnapshot = await getDocs(q);
            return availabilitySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    ...data,
                    startDate: data.startDate.toDate(),  // Convert Firestore timestamp to JavaScript Date object
                    endDate: data.endDate.toDate()       // Convert Firestore timestamp to JavaScript Date object
                };
            });
        });
    
        const allAvailabilities = (await Promise.all(allAvailabilitiesPromises)).flat();
        return allAvailabilities;
        
    }
    

    fetchBookingsForUserTalents = async () => {
        const talentIds = await this.fetchUserTalents();
      
        const allEventsPromises = talentIds.map(async talentId => {
            const bookingCollection = collection(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings');
            const q = query(bookingCollection, where("talentId", "==", talentId));
    
            const bookingSnapshot = await getDocs(q);
            const bookings = bookingSnapshot.docs.map(bookingDoc => ({
                id: bookingDoc.id,
                set:bookingDoc.set,
                ...bookingDoc.data()
            }));
    
            const vacations = await this.fetchVacationsForTalent(talentId);
            
            const vacationEvents = vacations.map(async vacation => {
                const eventName = `Vacation for ${await this.fetchTalent(talentId)}`;
                return {
                    id: `vacation-${vacation.startDate}-${vacation.endDate}`,
                    title: eventName,
                     set:"on Model",
                    start: new Date(vacation.startDate),
                    end: new Date(vacation.endDate),
                    allDay: true,
                    status: "Vacation",
                    company: "N/A",
                };
            });
    
            const bookingEvents = bookings.map(async booking => {
                const [talentName, ] = await Promise.all([
                    this.fetchTalent(booking.talentId),
                 //   this.fetchCompany(booking.companyID)
                ]);
                const clientName = booking.client ? booking.client.label : 'NULL';

                return {
                    bookingId: booking.id,
                    id: booking.id,
                    title: `Booking ${talentName} for  (${booking.status})`,
                    start: new Date(booking.start_date.seconds * 1000),
                    end: new Date(booking.end_date.seconds * 1000),
                    allDay: true,
                    status: booking.status,
                    client: clientName,
                    talent:talentName,
                    boktitle:booking.title,
                    set: booking.set,
                    kpi:booking.kpi,
                    option:booking.option,
                };
            });
    
            return [...await Promise.all(vacationEvents), ...await Promise.all(bookingEvents)];
        });
    
        const allEvents = (await Promise.all(allEventsPromises)).flat();
    
        this.setState({ events: allEvents });
    };
    handleSelectEvent = (event) => {
        const bookingId = event.bookingId;
        // Handle the event...
    };
    Event = ({ event }) => {
        
        let borderRadius;
        switch (event.set) {
          case 'On Model':
            borderRadius = '10px';
            break;
          case 'on Produkt':
            borderRadius = '0px';
            break;
        }
        return(
        <div className="my-div" style={{ cursor: 'auto',height:'100px', borderRadius:borderRadius }}> 
            <div>
                <CalendarEventStatusColor event={event} status={event.status} />
            </div>
            {event.status === "Vacation" ? (
                <span style={{ fontWeight: '700' }}>
                    {event.title}
                </span>
            ) : (
                <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
           
                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.client} `}</span>
             
                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.talent} `}</span>
                
                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.status} `}</span>
              </span>
            )}
            {event.status !== "Vacation" && (
                <div>
                  
                </div>
            )}
        </div>
    );}
    

    render() {

        return (
           < MyBookingsPageContext.Provider value={{ availabilities: this.state.availabilities }}>

        <section>
            <div className='content-wrapper'>
            <div className='h1-container' style={{ justifyContent: 'space-between' , display: 'flex', }}>
                <h1>Projects</h1>
                <a href="../talent/orders"> <button className={'big-button'  } >list</button></a>
                </div>
                <div>
                   {/* <Calendar
                    localizer={localizer}
                    events={this.state.events}
                    startAccessor="start"
                    endAccessor="end"
                    defaultView="week"
                    className="talent-calendar"
                   // defaultView={this.state.currentView}
                    style={{ height: 1800 }}
                    components={{
                       header: CustomDayHeader,
                    event: this.Event, // use the custom event component
                    toolbar: CalendarToolbar, // use the custom toolbar
                    
                    }}
                    date={this.state.currentDate}

                /> */}
                 <div className="calendar">
                      <MyCalendar
                        talentId={this.state.talentid} 
                       relatedTalents={this.state.related}
                      />
                    </div>

                {this.state.events.map(event => (
                <div key={event.id}>
                    {event.showDetail && (
                    <div
                        style={{
                        position: 'absolute',
                        width:"300px",
                        top:"auto",
                        boxShadow:'rgba(0, 0, 0, 0.15) 0px 0px 5px 0px',
                        left: "auto",
                        background: '#ffffff',
                        padding: '10px',
                        zIndex: 9999,
                        }}
                    >
                        <p>Booking ID: {event.id}</p>
                        <p>Start Date: { moment(event.start).format('DD-MM-YYYY')}</p>
                        <p>End Date:{ moment(event.end).format('DD-MM-YYYY')} </p>
                        {/* Add more details as needed */}
                    </div>
                    )}
                </div>
                ))}
                </div>
            </div>
        </section> </MyBookingsPageContext.Provider>
        );
    }
}

export default MyBookingsPage;
