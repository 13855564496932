import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  onSnapshot,
  orderBy,
  startAt,
  endAt,
  getDoc,
} from "firebase/firestore";
//import { Tooltip } from 'react-tooltip'
import "./../../../App.css";

import { db } from "./../../../firebaseConfig";
import { NonceProvider } from "react-select";
import { getWeek, startOfWeek, endOfWeek } from "date-fns";
import PropTypes from "prop-types";
import "./MyCalendar.css";

const TalentCalendarContext = React.createContext();

// Month names map
const monthNames = {
  JANUAR: 0,
  FEBRUAR: 1,
  MÄRZ: 2,
  APRIL: 3,
  MAI: 4,
  JUNI: 5,
  JULI: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OKTOBER: 9,
  NOVEMBER: 10,
  DEZEMBER: 11,
};
moment.locale("de");
//import 'moment/locale/de'; // Import the locale you want. In this case, German (Deutschland).
//const localizer = momentLocalizer(moment.locale('de')); // Set the locale to 'de' (German).

class CustomDayHeader extends React.Component {
  render() {
    const { date, label } = this.props;
    const { availabilities } = this.context;

    const isAvailable =
      availabilities &&
      availabilities.some(
        (availability) =>
          moment(date).isSameOrAfter(moment(availability.startDate)) &&
          moment(date).isSameOrBefore(moment(availability.endDate))
      );

    console.log(availabilities);
    const bgColor = isAvailable ? "#00d2ff" : "white";
    const bgColorClass = isAvailable ? "available-day" : "";

    return <div className={`rbc-header ${bgColorClass}`}>{label}</div>;
  }
}

CustomDayHeader.contextType = TalentCalendarContext;

class CustomToolbar extends React.Component {
  render() {
    const { label, date, view, onViewChange } = this.props;
    const weekNumber = getWeek(date);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <button className="button" onClick={() => this.navigate("PREV")}>
            Prev
          </button>
          <button className="button" onClick={() => this.navigate("NEXT")}>
            Next
          </button>
        </div>
        <div className="weeknumber-wrapper" style={{ marginBottom: "10px" }}>
          <label>
            Week {weekNumber}: <strong>{label}</strong>
          </label>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
 
        </div>
        {view === "day" && (
          <div style={{ marginBottom: "10px" }}>
            <button className="button" onClick={() => this.navigate("TODAY")}>
              Today
            </button>
          </div>
        )}
      </div>
    );
  }

  navigate = (action) => {
    const { date, view } = this.props;
    let newDate;
    switch (action) {
      case "PREV":
        newDate = view === "week" ? moment(date).subtract(1, "week") : moment(date).subtract(1, "day");
        break;
      case "NEXT":
        newDate = view === "week" ? moment(date).add(1, "week") : moment(date).add(1, "day");
        break;
      case "TODAY":
        newDate = moment();
        break;
      default:
        newDate = date;
    }
    this.props.onNavigate(action, newDate.toDate());
  };
}
const localizer = momentLocalizer(moment);
class MyCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.handleEventMouseEnter = this.handleEventMouseEnter.bind(this);
    const currentDate = props.initialDate
      ? new Date(props.initialDate)
      : new Date();
    const defaultView = window.innerWidth <= 768 ? "day" : "week"; // Set default view based on screen width

    this.state = {
      events: [],
      date: currentDate,
      startOfWeek: startOfWeek(currentDate),
      endOfWeek: endOfWeek(currentDate),
      currentDate: currentDate,
      availabilities: [],
      loading: true,
      defaultView: defaultView, // Add defaultView to state
    };
  }
  onNavigate = (action, newDate) => {
    switch (action) {
      case "PREV":
      case "NEXT":
      case "TODAY":
        this.setState({
          startOfWeek: startOfWeek(newDate),
          endOfWeek: endOfWeek(newDate),
          currentDate: newDate,
        });
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    const talentIds = [this.props.talentId, ...(this.props.relatedTalents ?? [])];
    this.fetchBookings(talentIds);
  }
  
  componentDidUpdate(prevProps) {
    if (
      prevProps.talentId !== this.props.talentId ||
      prevProps.relatedTalents !== this.props.relatedTalents
    ) {
      const talentIds = [this.props.talentId, ...(this.props.relatedTalents ?? [])];
      this.fetchBookings(talentIds);
    }
  }
  fetchTalentName = async (talentId) => {
    try {
      const talentRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data", talentId);
      const talentDoc = await getDoc(talentRef);
      if (talentDoc.exists()) {
        console.log(`Talent found: ${talentDoc.data().Name} for ID: ${talentId}`);
        return talentDoc.data().Name; // Assuming the talent document has a 'name' field
      } else {
        console.warn(`No talent found for ID: ${talentId}`);
      }
    } catch (error) {
      console.error("Error fetching talent name:", error);
    }
    return "Unknown Talent"; // Fallback if talent name is not found
  };
  fetchAvailabilityForTalent = async (talentIds) => {
    const availabilityCollection = collection(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentAvailability"
    );
    let allAvailabilities = [];

    for (const talentId of talentIds) {
      const q = query(
        availabilityCollection,
        where("talentId", "==", talentId)
      );
      const availabilitySnapshot = await getDocs(q);
      const talentName = await this.fetchTalentName(talentId); // Fetch the talent name

      const availabilities = availabilitySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
          type: "Available",
          talentName: talentName, // Ensure talent name is added here
        };
      });
      allAvailabilities = allAvailabilities.concat(availabilities);
    }

    return allAvailabilities;
  };

  fetchVacationsForTalent = async (talentIds) => {
    const vacationsCollection = collection(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentVacations"
    );
    let allVacations = [];

    for (const talentId of talentIds) {
      const q = query(vacationsCollection, where("talentId", "==", talentId));
      const vacationSnapshot = await getDocs(q);
      const talentName = await this.fetchTalentName(talentId); // Fetch the talent name
      console.log(talentName);

      const vacations = vacationSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
          type: "Vacation",
          talentName: talentName, // Ensure talent name is added here
        };
      });
      allVacations = allVacations.concat(vacations);
    }

    return allVacations;
  };
  fetchBookings = async (talentIds) => {
    try {
      console.log("these are ids" + talentIds);

      // Fetch bookings where the talentId matches the provided talentId
      const bookingsCollection = collection(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"
      );
      const q = query(bookingsCollection, where("talentId", "in", talentIds));
      const bookingSnap = await getDocs(q);
      const companySnap = await getDocs(
        collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company")
      );
      const companyData = companySnap.docs.map((doc) => ({
        value: doc.id,
        label: doc.data().name, // assumes the company document has a 'name' field
      }));

      const vacations = await this.fetchVacationsForTalent(talentIds);
      const vacationEvents = await Promise.all(
        vacations.map(async (vacation) => {
          const talentName = await this.fetchTalentName(vacation.talentId); // Fetch the talent name
          return {
            id: `vacation-${vacation.startDate}-${vacation.endDate}`,
            title: "Vacations",
            start: new Date(vacation.startDate),
            end: new Date(vacation.endDate),
            allDay: true,
            type: "Vacation",
            company: "N/A",
            talentName: talentName, // Include talent name
          };
        })
      );

      const availabilities = await this.fetchAvailabilityForTalent(talentIds);
      const availabilitiesEvents = await Promise.all(
        availabilities.map(async (available) => {
          const talentName = await this.fetchTalentName(available.talentId); // Fetch the talent name
          return {
            id: `Available-${available.startDate}-${available.endDate}`,
            title: "Available",
            start: new Date(available.startDate),
            end: new Date(available.endDate),
            allDay: true,
            type: "Available",
            company: "N/A",
            talentName: talentName, // Include talent name
          };
        })
      );

      // Map the bookings to match the event structure that react-big-calendar expects
      const events = await Promise.all(
        bookingSnap.docs.map(async (doc) => {
          const booking = doc.data();
          let companyID = booking.companyID;

          // Check if companyID is defined and is a string
          if (typeof companyID === "string") {
            companyID = companyID.split("/").pop();
          } else {
            companyID = "defaultCompanyID";
          }

          let company = companyData.find((company) => company.value === companyID);
          const companyname = company ? company.label : "Unknown Company";
          const start = booking.start_date
            ? moment(booking.start_date.toDate()).toDate()
            : null;
          const end = booking.end_date
            ? moment(booking.end_date.toDate()).toDate()
            : null;
          const client = booking.client ? booking.client.label : "NULL";
          
          // Fetch the talent name using the document ID
          const talentName = await this.fetchTalentName(booking.talentId);

          return {
            start: start,
            end: end,
            title: booking.title,
            bookingId: booking.id,
            id: doc.id,
            titles: `Booking (${booking.status})`,
            company: companyname,
            allDay: true,
            status: booking.status,
            showDetail: false,
            client: client,
            kpi: booking.kpi,
            segment: booking.segment,
            option: booking.option,
            set: booking.set,
            startTime: booking.startTime,
            endTime: booking.endTime,
            talentName: talentName, // Add talent name to event data
          };
        })
      );

      const combinedEvents = [
        ...events,
        ...vacationEvents,
        ...availabilitiesEvents,
      ];
      const uniqueEvents = combinedEvents.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      this.setState({ events: uniqueEvents, loading: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ loading: false });
    }
  };
  hideAllEventDetails = () => {
    const updatedEvents = this.state.events.map((event) => ({
      ...event,
      showDetail: false,
    }));
    this.setState({ events: updatedEvents });
  };
  handleEventMouseEnter = (eventId) => {
    const updatedEvents = this.state.events.map((event) => {
      if (event.id === eventId) {
        return { ...event,  showDetail: true }; // Set showDetail to true for the hovered event
      }
      return event;
    });
    this.setState({ events: updatedEvents });
  };
  handleEventMouseLeave = (eventId) => {
    const updatedEvents = this.state.events.map((event) => {
      if (event.id === eventId) {
        return { ...event, showDetail: false }; // Set showDetail back to false for the event when mouse leaves
      }
      return event;
    });
    this.setState({ events: updatedEvents });
  };

  Event = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
      case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
      case 'hold':
        statusColor = '#800080';
        break;
      case 'optionSolved':
        statusColor = '#FFFF00';
        break;
      case 'lano':
        statusColor = '#116c11';
        break;
      case 'Booked':
        statusColor = '#ADD8E6';
        break;
      default:
        statusColor = '#606060';
    }

    let borderRadius;
    switch (event.set) {
      case "On Model":
        borderRadius = "10px";
        break;
      case "On Produkt":
        borderRadius = "0px";
        break;
    }
    const getInitials = (name) => {
        return name
          .split(' ')
          .map((word) => word[0])
          .join('')
          .toUpperCase();
      };
    let backgroundColor;
    let displayText;
    if (event.type === "Vacation") {
      backgroundColor = "#F12A5D";
      displayText = ` ${getInitials(event.talentName)}`; // Include talent name for Vacation
    } else if (event.type === "Available") {
      backgroundColor = "#2ACEF1";
      displayText = ` ${getInitials(event.talentName)}`; // Include talent name for Available
    } else {
      backgroundColor = "#FFFFFF"; // Set default background color for bookings to white
    }  // Ensure this function is defined outside of any class or component


    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          margin: "3px",
          position: "relative",
          color: "#000000",
          minHeight: event.type === "Vacation" || event.type === "Available" ? "20px" : "20px",
          cursor: "auto",
        }}
      >
        {event.type === "Vacation" || event.type === "Available" ? (
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {displayText}
          </div>
        ) : (
          <div>
            <span
              style={{
                height: "30px",
                width: "30px",
                backgroundColor: statusColor,
                borderRadius: "50%",
                display: "inline-block",
                position: "absolute",
              
                paddingTop: "5px",
                margin:"3px",
             
                textAlign: "center",
                fontWeight: "bold",
          
              }} >   {` ${getInitials(event.talentName)} `} </span>
            
     
          </div>
        )}
      </div>
    );
  };
  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    console.log(
      "TalentCalendar render method called with date: ",
      this.state.date
    );
    const localizer = momentLocalizer(moment);
    return (
      <TalentCalendarContext.Provider
        value={{ availabilities: this.state.availabilities }}
      >
        <div>
          <Calendar
            localizer={localizer}
            date={this.state.date}
            events={this.state.events}
            style={{ height: 800 }}
            startAccessor="start"
            endAccessor="end"
            defaultView={'week'} // Use defaultView from state
            className="talent-calendar"
            components={{
              event: this.Event,
              header: CustomDayHeader,
              toolbar: (props) => (
                <CustomToolbar
                  {...props}
                  date={this.state.date}
                  onNavigate={this.onNavigate}
                  onViewChange={(view) => this.setState({ defaultView: view })} // Update view state
                  onWeekChange={this.onWeekChange}
                  date={this.state.currentDate}
                />
              ),
            }}
            onNavigate={this.onNavigate}
            date={this.state.currentDate}
          />
          {this.state.events.map((event) => (
            <div key={event.id}>
              {event.showDetail && (
                <div
                  className="toltip"
                  style={{
                    position: "fixed",
                    width: "500px",
                    height: "300px",
                    top: "10%",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 5px 0px",
                    left: "auto",
                    background: "#ffffff",
                    padding: "10px",
                    zIndex: 9999,
                  }}
                >
                  <p>Booking ID: {event.id}</p>
                  <p>Start Date: {moment(event.start).format("DD-MM-YYYY")}</p>
                  <p>End Date: {moment(event.end).format("DD-MM-YYYY")}</p>
                  <p>Client: {event.client}</p>
                  <p>Kpi: {event.kpi}</p>
                  {/* Add more details as needed */}
                </div>
              )}
            </div>
          ))}
        </div>
      </TalentCalendarContext.Provider>
    );
  }
}


export default MyCalendar;
