import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  onSnapshot,
  orderBy,
  startAt,
  endAt,
} from "firebase/firestore";
//import { Tooltip } from 'react-tooltip'
import "./../App.css";
import CalendarToolbar from "./CalendarToolbar";
import CalendarEventStatusColor from "./CalendarEventStatusColor";
import { db } from "./../firebaseConfig";
import { NonceProvider } from "react-select";
import { getWeek, startOfWeek, endOfWeek } from "date-fns";
import PropTypes from "prop-types";

const TalentCalendarContext = React.createContext();

// Month names map
const monthNames = {
  JANUAR: 0,
  FEBRUAR: 1,
  MÄRZ: 2,
  APRIL: 3,
  MAI: 4,
  JUNI: 5,
  JULI: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OKTOBER: 9,
  NOVEMBER: 10,
  DEZEMBER: 11,
};
moment.locale("de");
//import 'moment/locale/de'; // Import the locale you want. In this case, German (Deutschland).
//const localizer = momentLocalizer(moment.locale('de')); // Set the locale to 'de' (German).

class CustomDayHeader extends React.Component {
  render() {
    const { date, label } = this.props;
    const { availabilities } = this.context;

    // Convert the date to ISO string and get just the date part for comparison
    const dateKey = moment(date).format('YYYY-MM-DD');

    // Find the most recent availability or vacation for this specific day
    const dayEvent = availabilities.find(event => 
      moment(event.date).format('YYYY-MM-DD') === dateKey
    );

    // Determine the class based on the event type
    let bgColorClass = '';
    if (dayEvent) {
      bgColorClass = dayEvent.type === 'Available' ? 'available-day' : 'vacation-day';
    }

    return <div  style={{backgroundColor: bgColorClass, height: "45px", }} className={`rbc-header ${bgColorClass}`}>{label}</div>;
  }
}

CustomDayHeader.contextType = TalentCalendarContext;

class CustomToolbar extends React.Component {
  render() {
    let { label } = this.props;
    // Extract the month and start day from the label.
    const month = label.split(" ")[0].toUpperCase();
    const day = label.split(" ")[1].split("–")[0];
    // Construct the date using the extracted day and month.
    let date = new Date();
    date.setMonth(monthNames[month]);
    date.setDate(day);
    date = startOfWeek(date);
    // Get the week number using date-fns
    let weekNumber = getWeek(date);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <div>
          <button className="button" onClick={() => this.navigate("TODAY")}>
            today
          </button>
        </div>
        <div className="weeknumber-wrapper">
          <label>
            Week {weekNumber}: <strong>{label}</strong>
          </label>
        </div>
        <div>
          <button className="button" onClick={() => this.navigate("PREV")}>
            Prev
          </button>
          <button className="button" onClick={() => this.navigate("NEXT")}>
            Next
          </button>
        </div>
      </div>
    );
  }
  navigate = (action) => {
    const { date } = this.props; // current date from the Calendar's props
    let newDate;
    switch (action) {
      case "PREV":
        newDate = moment(date).subtract(1, "week");
        break;
      case "NEXT":
        newDate = moment(date).add(1, "week");
        break;
      case "TODAY":
        newDate = moment();
        break;
      default:
        newDate = date;
    }
    // Notify the parent component
    this.props.onNavigate(action, newDate.toDate());
    // Notify about the week change
  };
  view = (view) => {
    this.props.onView(view);
  };
}
const localizer = momentLocalizer(moment);
class TalentCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.handleEventMouseEnter = this.handleEventMouseEnter.bind(this);
    const currentDate = props.initialDate
      ? new Date(props.initialDate)
      : new Date(); // Store the initial date in a variable
    this.state = {
      events: [],
      date: currentDate,
      startOfWeek: startOfWeek(currentDate),
      endOfWeek: endOfWeek(currentDate),
      currentDate: currentDate,
      availabilities: [],
    };
  }
  onNavigate = (action, newDate) => {
    switch (action) {
      case "PREV":
      case "NEXT":
      case "TODAY":
        this.setState({
          startOfWeek: startOfWeek(newDate),
          endOfWeek: endOfWeek(newDate),
          currentDate: newDate, // Update the current date when navigating
        });
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    // Check if this.props.relatedTalents is undefined or null and default to an empty array if it is
    const talentIds = [this.props.talentId, ...(this.props.relatedTalents ?? [])];
    this.fetchBookings(talentIds);

    this.fetchAvailabilityForTalent([this.props.talentId]).then(
      (availabilities) => this.setState({ availabilities })
    );
  }

  componentDidUpdate(prevProps) {
    // Similarly, check in componentDidUpdate
    if (
      prevProps.talentId !== this.props.talentId ||
      prevProps.relatedTalents !== this.props.relatedTalents
    ) {
      const talentIds = [this.props.talentId, ...(this.props.relatedTalents ?? [])];
      this.fetchBookings(talentIds);
    }
  }
  fetchAvailabilityForTalent = async (talentIds) => {
    const availabilityCollection = collection(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentAvailability"
    );
    let allAvailabilities = [];

    for (const talentId of talentIds) {
      const q = query(
        availabilityCollection,
        where("talentId", "==", talentId)
      );
      const availabilitySnapshot = await getDocs(q);
      const availabilities = availabilitySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
          type: "Available",
          addedAt: data.addedAt?.toDate() || new Date(0),
        };
      });
      allAvailabilities = allAvailabilities.concat(availabilities);
    }

    return allAvailabilities.sort((a, b) => b.addedAt - a.addedAt);
  };

  fetchVacationsForTalent = async (talentIds) => {
    const vacationsCollection = collection(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentVacations"
    );
    let allVacations = [];

    for (const talentId of talentIds) {
      const q = query(vacationsCollection, where("talentId", "==", talentId));
      const vacationSnapshot = await getDocs(q);
      const vacations = vacationSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
          type: "Vacation",
          addedAt: data.addedAt?.toDate() || new Date(0),
        };
      });
      allVacations = allVacations.concat(vacations);
    }

    return allVacations.sort((a, b) => b.addedAt - a.addedAt);
  };
  fetchBookings = async (talentIds) => {
    // Fetch bookings first
    const bookingsCollection = collection(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"
    );
    const q = query(bookingsCollection, where("talentId", "in", talentIds));
    const bookingSnap = await getDocs(q);
    const companySnap = await getDocs(
      collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company")
    );
    const companyData = companySnap.docs.map((doc) => ({
      value: doc.id,
      label: doc.data().name,
    }));

    // Map the bookings to events
    const events = bookingSnap.docs.map((doc) => {
      const booking = doc.data();
      let companyID = booking.companyID;
      if (typeof companyID === "string") {
        companyID = companyID.split("/").pop();
      } else {
        companyID = "defaultCompanyID";
      }
      let company = companyData.find((company) => company.value === companyID);
      const companyname = company ? company.label : "Unknown Company";
      const start = booking.start_date
        ? moment(booking.start_date.toDate()).toDate()
        : null;
      const end = booking.end_date
        ? moment(booking.end_date.toDate()).toDate()
        : null;
      const client = booking.client ? booking.client.label : "NULL";
      return {
        start: start,
        end: end,
        title: booking.title,
        bookingId: booking.id,
        id: doc.id,
        titles: `Booking (${booking.status})`,
        company: companyname,
        allDay: true,
        status: booking.status,
        showDetail: false,
        client: client,
        kpi: booking.kpi,
        segment: booking.segment,
        option: booking.option,
        set: booking.set,
        startTime: booking.startTime,
        endTime: booking.endTime,
      };
    });

    const vacations = await this.fetchVacationsForTalent(talentIds);
    const availabilities = await this.fetchAvailabilityForTalent(talentIds);

    // Create a map of daily events
    const dateEvents = {};
    
    // Process vacations
    vacations.forEach(vacation => {
      const start = new Date(vacation.startDate);
      const end = new Date(vacation.endDate);
      
      for (let date = start; date <= end; date = new Date(date.setDate(date.getDate() + 1))) {
        const dateKey = moment(date).format('YYYY-MM-DD');
        if (!dateEvents[dateKey] || vacation.addedAt > dateEvents[dateKey].addedAt) {
          dateEvents[dateKey] = {
            type: 'Vacation',
            addedAt: vacation.addedAt,
            date: new Date(date)
          };
        }
      }
    });

    // Process availabilities
    availabilities.forEach(availability => {
      const start = new Date(availability.startDate);
      const end = new Date(availability.endDate);
      
      for (let date = start; date <= end; date = new Date(date.setDate(date.getDate() + 1))) {
        const dateKey = moment(date).format('YYYY-MM-DD');
        if (!dateEvents[dateKey] || availability.addedAt > dateEvents[dateKey].addedAt) {
          dateEvents[dateKey] = {
            type: 'Available',
            addedAt: availability.addedAt,
            date: new Date(date)
          };
        }
      }
    });

    // Convert the dateEvents map to an array
    const dailyEvents = Object.values(dateEvents);

    // Update both the events state and the availabilities state
    const availabilityEvents = dailyEvents.map(event => ({
      id: `${event.type}-${event.date.toISOString()}`,
      title: event.type,
      start: event.date,
      end: new Date(new Date(event.date).setHours(23, 59, 59)),
      allDay: true,
      type: event.type,
      company: "N/A",
      addedAt: event.addedAt,
      date: event.date
    }));

    const combinedEvents = [
      ...events,
      ...availabilityEvents
    ];

    const uniqueEvents = combinedEvents.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.setState({ 
      events: uniqueEvents,
      availabilities: availabilityEvents // Store the daily events for the context
    });
  };
  hideAllEventDetails = () => {
    const updatedEvents = this.state.events.map((event) => ({
      ...event,
      showDetail: false,
    }));
    this.setState({ events: updatedEvents });
  };
  handleEventMouseEnter = (eventId) => {
    const updatedEvents = this.state.events.map((event) => {
      if (event.id === eventId) {
        return { ...event, showDetail: true }; // Set showDetail to true for the hovered event
      }
      return event;
    });
    this.setState({ events: updatedEvents });
  };
  handleEventMouseLeave = (eventId) => {
    const updatedEvents = this.state.events.map((event) => {
      if (event.id === eventId) {
        return { ...event, showDetail: false }; // Set showDetail back to false for the event when mouse leaves
      }
      return event;
    });
    this.setState({ events: updatedEvents });
  };
  Event = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
        case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
      case 'hold':
        statusColor = '#800080';
        break;
        case 'optionSolved':
          statusColor = '#FFFF00';
          break;
      case 'lano':
        statusColor = '#116c11';
        break;
        case 'Booked':
          statusColor = '#ADD8E6';
          break;
      default:
        statusColor = '#606060';
    }
    let borderRadius;
    switch (event.set) {
      case "On Model":
        borderRadius = "10px";
        break;
      case "On Produkt":
        borderRadius = "0px";
        break;
    }

    if (event.type === "Vacation" || event.type === "Available") {
      const backgroundColor = event.type === "Vacation" ? "#F12A5D" : "#2ACEF1";
      
      return (
        <div className="availability-event-wrapper">
       
        </div>
      );
    }

    // Regular events remain unchanged
    return (
      <div
        style={{
          border: "1px solid black",
          margin: "3px",
          borderRadius: borderRadius,
          padding: "10px",
          position: "relative",
          color: "#000000",
          minHeight: "123px",
          cursor: "auto",
        }}
      >
        <div>
          <span
            style={{
              height: "22px",
              width: "22px",
              backgroundColor: statusColor,
              borderRadius: "50%",
              display: "inline-block",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          />
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "83%",
              display: "inline-block",
              whiteSpace: "nowrap",
              fontSize: ".7rem",
            }}
          >{` ${event.startTime}-  ${event.endTime}`}</span>

          <span
            style={{
              position: "relative",
              fontWeight: "600",
              color: "#000000",
              whiteSpace: "pre-line",
              fontSize: ".8rem",
              lineHeight: "1",
            }}
          >
            <div
              style={{
                fontWeight: "300",
                fontSize: ".7rem",
                lineHeight: "1",
              }}
            >
              {` Client `}
            </div>
            {` ${event.client} `}
            <div
              style={{
                fontWeight: "300",
                fontSize: ".7rem",
                lineHeight: "1",
                marginTop: "2px",
              }}
            >
              {` Status `}
            </div>
            {` ${event.status} `}

          </span>
        </div>
      </div>
    );
  };
  render() {
    console.log(
      "TalentCalendar render method called with date: ",
      this.state.date
    );
    const localizer = momentLocalizer(moment);
    return (
      <TalentCalendarContext.Provider
        value={{ availabilities: this.state.availabilities }}
      >
        <div>
          <Calendar
            localizer={localizer}
            date={this.state.date}
            events={this.state.events}
            style={{ height: 500 }}
            startAccessor="start"
            endAccessor="end"
            defaultView="week"
            className="talent-calendar"
            /*  onMouseLeave={event => this.handleEventMouseLeave(event.id)}
            onSelectEvent={event => this.handleEventMouseEnter(event.id)}
                components={{
            event: this.Event, // use the custom event component
            toolbar: props => <CustomToolbar {...props}  onNavigate={this.onNavigate}    date={this.state.date} />, // Pass the date
          }}
          onNavigate={this.onNavigate}
          */
            components={{
              event: this.Event,

              header: CustomDayHeader,
              //  header: CustomDay, // <-- This is what you might be looking for

              toolbar: (props) => (
                <CustomToolbar
                  {...props}
                  date={this.state.date}
                  onNavigate={this.onNavigate}
                  onWeekChange={this.onWeekChange}
                  date={this.state.currentDate}
                />
              ),
            }}
            onNavigate={this.onNavigate}
            date={this.state.currentDate}
          />
          {this.state.events.map((event) => (
            <div key={event.id}>
              {event.showDetail && (
                <div
                  className="toltip"
                  style={{
                    position: "fixed",
                    width: "500px",
                    height: "300px",
                    top: "10%",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 5px 0px",
                    left: "auto",
                    background: "#ffffff",
                    padding: "10px",
                    zIndex: 9999,
                  }}
                >
                  <p>Booking ID: {event.id}</p>
                  <p>Start Date: {moment(event.start).format("DD-MM-YYYY")}</p>
                  <p>End Date:{moment(event.end).format("DD-MM-YYYY")} </p>
                  <p>Client:{event.client} </p>
                  <p>Kpi:{event.kpi} </p>
                  <p>Kpi:{event.kpi} </p>
                  <p>Kpi:{event.kpi} </p>
                  {/* Add more details as needed */}
                </div>
              )}
            </div>
          ))}
        </div>
      </TalentCalendarContext.Provider>
    );
  }
}
export default TalentCalendar;